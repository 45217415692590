import { Line } from "@ant-design/charts";
import PropTypes from "prop-types";
import * as utilities from "../../data/utilities/utilities";

const TrackTempChart: React.FC<{ sensorData: any }> = ({ sensorData }) => {
  console.log(sensorData.getSensorDataByIdByTime);
  const data = sensorData.getSensorDataByIdByTime;
  sensorData.forEach((element: any) => {
    element.key = element["nodeId"];
    const timestamp = Number(element["max_ts"]);
    element["timestamp"] = utilities.convertTimestamp(timestamp);
    // element["timestamp"] = timestamp;
    element["temperature"] = Number(element["max_temp"]);
  });
  console.log("Converted data in chart", sensorData);
  const config = {
    data: sensorData,
    xField: "timestamp",
    yField: "temperature",
    // seriesField: "date",
    yAxis: {
      tickCount: 5,
    },
    xAxis: {
      type: "timeCat",
    },
    color: "red",
  };
  return <Line {...config} />;
};

TrackTempChart.propTypes = {
  sensorData: PropTypes.any.isRequired,
};

export default TrackTempChart;
