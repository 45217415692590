const address = window.location.href;
let url: string;
export const mode = "track";

if (mode === "track") {
  if (address.includes("localhost")) {
    url = "http://192.168.16.12:4002";
  } else {
    url = "https://api.track.therma.link";
  }
} else {
  if (address.includes("localhost")) {
    url = "http://192.168.16.12:4000";
  } else {
    url = "https://api.therma.link";
  }
}

export const headers = {
  "Content-Type": "application/json",
  Authorization: "",
};

// export const organisationId2 = "fa3a52f6-3766-414d-9106-329265e97ff1";
// export const organisationId = "95e6d7e8-8068-42e4-b2ed-08d84c0df40e";

export async function sendQuery(
  query: string,
  variables: Record<string, unknown>,
  headers: Record<string, string>
): Promise<Record<string, Record<string, Record<string, unknown>[]>>> {
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ query, variables }), // Coordinate the body type with 'Content-Type'
  });
  return await response.json(); // convert to JSON
}

export async function sendQueryReturnObj(
  query: string,
  variables: Record<string, unknown>,
  headers: Record<string, string>
): Promise<Record<string, Record<string, Record<string, string>[]>>> {
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ query, variables }), // Coordinate the body type with 'Content-Type'
  });
  return await response.json(); // convert to JSON
}

export async function sendQueryReturnObjObj(
  query: string,
  variables: Record<string, unknown>,
  headers: Record<string, string>
): Promise<Record<string, Record<string, Record<string, Record<string, string>>>>> {
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ query, variables }), // Coordinate the body type with 'Content-Type'
  });
  return await response.json(); // convert to JSON
}

export const getSensorDataQuery =
  mode === "track"
    ? `query getSensorData($nodeId: String, $orgId: String) {
  sensorData(nodeId: $nodeId, orgainsationId: $orgId) {
    arrivedts
    min_temp
    max_temp
    min_ts
		max_ts
		batt_mv
  }
}
`
    : "query{\
  getSensorData{\
    nodeId\
    pressure\
    temperature\
    batt\
    humidity\
  }\
}";

export const getSensorDataByIdQuery =
  "query($nodeId:String){\
  getSensorDataById(nodeId:$nodeId){\
    nodeId\
    pressure\
    temperature\
    batt\
    humidity\
    arrivedtime\
    measuredtime\
  }\
}";

export const getSensorDataByIdByTimeQuery =
  mode === "track"
    ? `query getSensorDataByTime($nodeId: String!, $after: String) {
  getSensorDataByIdByTime(nodeId: $nodeId, after:$after) {
    arrivedts
    nodeId
    min_ts
    min_temp
    max_ts
    max_temp
    batt_mv
  }
}
`
    : "query($nodeId:String,$after:String){\
  getSensorDataByIdByTime(nodeId:$nodeId,after:$after){\
    nodeId\
    pressure\
    temperature\
    batt\
    humidity\
    arrivedtime\
    measuredtime\
  }\
}";

export const getSummaryQuery =
  'query{\
  getSummary(organisationId:"fa3a52f6-3766-414d-9106-329265e97ff1")\
  {\
    nodeId\
    max_temperature\
    min_temperature\
    avg_temperature\
    max_humidity\
    min_humidity\
    avg_humidity\
  }\
}';

export const getLatestQuery =
  'query{\
  getLatest(organisationId:"fa3a52f6-3766-414d-9106-329265e97ff1"){\
    nodeId\
    pressure\
    temperature\
    batt\
    humidity\
    arrivedtime\
    location\
    locationId\
    locationType\
    locationName\
    alertEnabled\
    maxThreshold\
  }\
}';

export const getLatestQueryWithVars =
  mode === "track"
    ? "query( $organisationId:String! ){\
  getLatest(organisationId:$organisationId){\
    arrivedts\
    nodeId\
    min_ts\
    max_ts\
    min_temp\
    max_temp\
    batt_mv\
    lat\
    log\
  }\
}"
    : "query( $organisationId:String ){\
  getLatest(organisationId:$organisationId){\
    nodeId\
    pressure\
    temperature\
    batt\
    humidity\
    arrivedtime\
    measuredtime\
    location\
    locationId\
    locationType\
    locationName\
    alertEnabled\
    maxThreshold\
  }\
}";

export const loginMutation =
  mode === "track"
    ? "mutation( $email:String!, $password:String! ){\
  login( email:$email, password:$password ){\
    user {\
      name\
      email\
      role\
    }\
    organisation {\
      id\
      name\
    }\
    token\
  }\
}"
    : "mutation( $email:String!, $password:String! ){\
  login( data: { email:$email, password:$password } ){\
    user {\
      name\
      email\
      organisationId\
      role\
    }\
    organisation {\
      id\
      name\
      alertEmail\
      alertTelegram\
      reportEmail\
    }\
    token\
  }\
}";

export const addUserMutation =
  "mutation( $name:String, $email:String!, $password:String!, $organisationId:String!, $role:String! ){\
  addUser( data: { name:$name, email:$email, password:$password, organisationId:$organisationId, role:$role } ){\
    name\
    email\
    role\
  }\
}";

export const updateSensorMutation =
  "mutation( $sensorDataInput: sensorDataInput! ){\
  updateSensor( data: $sensorDataInput ){\
    nodeId\
    settings\
  }\
}";

export const addReportEmailIdMutation =
  "mutation($reportEmailDataInput :reportEmailDataInput){\
  addReportEmailId(data: $reportEmailDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";

export const addAlertEmailIdMutation =
  "mutation($alertEmailDataInput :alertEmailDataInput){\
  addAlertEmailId(data: $alertEmailDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";

export const addTelegramChatIdMutation =
  "mutation($telegramChatIdDataInput :telegramChatIdDataInput){\
  addTelegramChatId(data: $telegramChatIdDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";

export const removeReportEmailIdMutation =
  "mutation($reportEmailDataInput :reportEmailDataInput){\
  removeReportEmailId(data: $reportEmailDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";

export const removeAlertEmailIdMutation =
  "mutation($alertEmailDataInput :alertEmailDataInput){\
  removeAlertEmailId(data: $alertEmailDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";

export const removeTelegramChatIdMutation =
  "mutation($telegramChatIdDataInput :telegramChatIdDataInput){\
  removeTelegramChatId(data: $telegramChatIdDataInput){\
    id\
    name\
    alertEmail\
    reportEmail\
    alertTelegram\
  }\
}";
