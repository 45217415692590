import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Spin, message } from 'antd';
import AuthContext from '../../../data/session/UserSession/UserSession';
import * as queries from '../../../data/api/queries';
import './LoginForm.css';

const LoginForm : React.FC = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    authCtx.logout();
  } , []);

  const onFinish = (values: Record<string, string>) => {
    setIsLoading(true);

    const query = queries.loginMutation;
    const { headers } = queries;
    const variables = {
      email: values.email,
      password: values.password,
    }

    queries.sendQueryReturnObjObj(query, variables, headers)
    .then((data) => {
      if ('errors' in data) {
        setIsLoading(false);
        if (data.errors.message) {
          message.error(data.errors.message);
        } else if (data.errors[0].message) {
          message.error(data.errors[0].message);
        } else {
          message.error('Authentication failed');
        }
      } else {
        setIsLoading(false);
        console.log(data)
        const { token } = data.data.login
        const { role } = data.data.login.user;
        const organisationId = data.data.login.organisation.id;
        const { name } = data.data.login.organisation;
        localStorage.setItem('OrganisationName', name);
        localStorage.setItem('OrganisationDetails', JSON.stringify(data.data.login.organisation));
        const expirationTime = new Date(
          new Date().getTime() + (60 * 60 * 24 * 1000)
        );
        authCtx.login(token.toString(), expirationTime.toISOString(), role, organisationId);
        form.resetFields();
        history.push({
          pathname: '/admin/dashboard',
        });
      }
    })
    .catch((err) => {
      setIsLoading(false);
      message.error('Connection error: ' + err.message);
    });
  };

  return (
    <Form
      form={form}
      className="login-form"
      layout="vertical"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {isLoading ? <div className='login-spinner'><Spin /></div> : null}
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email address' }]}
      >
        <Input placeholder='Email' />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password' }]}
      >
        <Input.Password placeholder='Password' />
      </Form.Item>

      <Form.Item>
        <Button className="login-form-btn" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;