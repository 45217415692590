import React, { useState, useEffect } from "react";
import { Table, Card, Button, Modal, Typography, Space, Row, Col, Badge, Tooltip, Tabs, Drawer, message } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  LineChartOutlined,
  ControlOutlined,
  PushpinOutlined,
  UserAddOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import SensorDataCharts from "../../charts/SensorDataChart";
import TrackTempChart from "../../charts/TrackTempChart";
import SensorSettingsForm from "../../forms/SensorSettingsForm/SensorSettingsForm";
import AddUserForm from "../../forms/AddUserForm/AddUserForm";
import StateMarker from "../../markers/StateMarker/StateMarker";
import NotificationsTable from "../NotificationsTable/NotificationsTable";
import * as utilities from "../../../data/utilities/utilities";
import * as queries from "../../../data/api/queries";
import "./FreezerSensorTable.css";
import { ColumnFilterItem } from "antd/lib/table/interface";
import Map from "./Map";

const FreezerSensorTable: React.FC = () => {
  const { Title, Text } = Typography;
  const { TabPane } = Tabs;

  const [isAdmin] = useState(localStorage.getItem("Role") !== "user");

  const [sensorInfoData, setSensorInfoData] = useState<Record<string, string>[]>([]);
  const orgName = localStorage.getItem("OrganisationName") ?? "Unknown";
  const orgDetailsString = localStorage.getItem("OrganisationDetails");
  let orgDetails = {};
  if (orgDetailsString) {
    orgDetails = JSON.parse(orgDetailsString);
  }

  const query = queries.getLatestQueryWithVars;
  const { headers } = queries;
  headers["Authorization"] = localStorage.getItem("Authorization") ?? "";
  const variables = {
    organisationId: localStorage.getItem("OrganisationId"),
  };
  const [isDownloading, setIsDownloading] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationFilter, setLocationFilter] = useState(false);
  const [uniqueLocations, setUniqueLocations] = useState<ColumnFilterItem[]>([]);

  useEffect(() => {
    setIsLoading(true);
    queries.sendQueryReturnObj(query, variables, headers).then((data) => {
      console.log("Data!!!", data);
      if ("errors" in data) {
        setIsLoading(false);
        message.error("Data unavailable");
      } else {
        const dataSource = data.data.getLatest;
        console.log("DataSource!!!", dataSource);
        const tempIsDownloadingArray: boolean[] = [];
        dataSource.forEach((element, index) => {
          element.index = String(index);
          element.key = element["nodeId"];
          // const timestamp = Number(element["measuredtime"]);
          // element["timestamp"] = utilities.convertTimestamp(timestamp);
          if (queries.mode === "track") {
            const maxTime = Number(element["max_ts"]);
            const minTime = Number(element["min_ts"]);
            const arrived = Number(element["arrivedts"]);
            element["maxtime"] = utilities.convertTimestamp(maxTime);
            element["mintime"] = utilities.convertTimestamp(minTime);
            element["arrivedTS"] = utilities.convertTimestamp(arrived);
          }
          tempIsDownloadingArray.push(false);
        });
        setSensorInfoData(dataSource);
        const uniqueLocationsArr = dataSource
          .map((item) => {
            return item.location;
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        const locationsFilterArr: ColumnFilterItem[] = [];
        uniqueLocationsArr.sort().forEach((location) => {
          const tempLocation: ColumnFilterItem = {
            text: location,
            value: location,
          };
          locationsFilterArr.push(tempLocation);
        });
        if (uniqueLocationsArr.length <= 12) {
          setLocationFilter(true);
          setUniqueLocations(locationsFilterArr);
        }
        setIsDownloading(tempIsDownloadingArray);
        setIsLoading(false);
      }
    });
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMapVisible, setMapVisible] = useState(false);
  const [lat, setLat] = useState(0.0);
  const [lon, setLon] = useState(0.0);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleNewUserModalOk = () => {
    setIsNewUserModalVisible(false);
  };

  const handleNewUserModalCancel = () => {
    setIsNewUserModalVisible(false);
  };

  const showNewUserModal = () => {
    setIsNewUserModalVisible(true);
  };

  const [modalTitle, setModalTitle] = useState("Sensor data");
  const [sensorType, setSensorType] = useState("");
  const [modalData, setModalData] = useState<any>({});
  const [settingsData, setSettingsData] = useState<Record<string, string>>({});

  const showModal = (record: Record<string, string>) => {
    const downloadStatus = isDownloading;
    const index = Number(record.index);

    const query = queries.getSensorDataByIdByTimeQuery;
    const { headers } = queries;
    headers["Authorization"] = localStorage.getItem("Authorization") ?? "";
    const variables = {
      nodeId: record.nodeId,
      after: utilities.getTwoWeeksAgoTimestamp(),
    };
    let sensorReport;

    queries.sendQueryReturnObj(query, variables, headers).then((data) => {
      if ("errors" in data || !data.data.getSensorDataByIdByTime) {
        message.error("Data unavailable");
      } else {
        sensorReport = queries.mode === "track" ? data.data.getSensorDataByIdByTime : data.data;
        console.log("Chart data", sensorReport);
        setModalTitle(
          queries.mode !== "track"
            ? "Sensor data for sensor " +
            record.locationName +
            " of type " +
            record.locationType +
            " at " +
            record.location
            : "Sensor data"
        );
        setSensorType(record.locationType);
        setModalData(sensorReport);
        setIsModalVisible(true);
        downloadStatus[index] = false;
        setIsDownloading(downloadStatus);
      }
    });
  };

  const showSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSettingsOk = () => {
    setIsSettingsModalVisible(false);
  };

  const handleSettingsCancel = () => {
    setIsSettingsModalVisible(false);
  };

  const showDrawer = () => {
    console.log(orgDetails);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const showMap = (lat: any, long: any) => {
    setLat(lat ? Number(lat) : 0.0);
    setLon(long ? Number(long) : 0.0);
    setMapVisible(true);
  };

  const staticColumnsPrefix: ColumnsType<Record<string, string>> = [
    {
      title: "Sensor ID",
      dataIndex: "nodeId",
      key: "nodeId",
    },
    {
      title: "Name",
      dataIndex: "locationName",
      key: "locationName",
      align: "center",
      sorter: (a, b) => {
        return a.locationName.localeCompare(b.locationName);
      },
    },
  ];
  const locationNameColumn: ColumnsType<Record<string, string>> = isLocationFilter
    ? [
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        align: "center",
        filters: uniqueLocations,
        onFilter: (value, record) => {
          return record.location.indexOf(String(value)) === 0;
        },
      },
    ]
    : [
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        align: "center",
        sorter: (a, b) => {
          return a.location.localeCompare(b.location);
        },
      },
    ];
  const staticColumns: ColumnsType<Record<string, string>> = [
    {
      title: "Type",
      dataIndex: "locationType",
      key: "locationType",
      align: "center",
      filters: [
        {
          text: "Chiller",
          value: "Chiller",
        },
        {
          text: "Freezer",
          value: "Freezer",
        },
        {
          text: "Room",
          value: "Room",
        },
      ],
      onFilter: (value, record) => {
        return record.locationType.indexOf(String(value)) === 0;
      },
    },
    {
      title: "T" + String.fromCharCode(176) + "C",
      key: "temperature",
      align: "center",
      sorter: (a, b) => {
        const aNum = Number(a.temperature);
        const bNum = Number(b.temperature);
        return aNum - bNum;
      },
      render: (record: Record<string, string>) => {
        let type = "success";
        if (Number(record.temperature) > Number(record.maxThreshold)) {
          type = "danger";
        }
        return (
          <Space>
            <StateMarker type={type} />
            <Text>{record.temperature}</Text>
          </Space>
        );
      },
    },
    {
      title: "Humidity",
      dataIndex: "humidity",
      key: "humidity",
      align: "center",
    },
    {
      title: "Battery",
      dataIndex: "batt",
      key: "batt",
      align: "center",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      sorter: (a, b) => {
        return a.timestamp.localeCompare(b.timestamp);
      },
    },
    {
      title: "Alerts",
      dataIndex: "alertEnabled",
      key: "alertEnabled",
      align: "center",
      render: (alertStatus: string) => {
        const alertStatusText = alertStatus == "true" ? "On" : "Off";
        const alertStatusComponent =
          alertStatus == "true" ? (
            <Tooltip placement="top" title={alertStatusText}>
              <Badge dot>
                <NotificationOutlined />
              </Badge>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={alertStatusText}>
              <NotificationOutlined style={{ color: "#ccc" }} />
            </Tooltip>
          );
        return alertStatusComponent;
      },
    },
    {
      title: "Details",
      key: "details",
      align: "center",
      render: (record: Record<string, string>) => {
        const index = Number(record.index);
        return (
          <Space>
            <Tooltip placement="top" title="View temperature and humidity charts">
              <Button
                loading={isDownloading[index]}
                key={record.nodeId + "-chart"}
                icon={<LineChartOutlined />}
                onClick={() => {
                  const downloadStatus = isDownloading;
                  const index = Number(record.index);
                  downloadStatus[index] = true;
                  setIsDownloading([...downloadStatus]);
                  return showModal(record);
                }}
              />
            </Tooltip>
            {isAdmin && (
              <Button
                key={record.nodeId + "-settings"}
                icon={<ControlOutlined />}
                onClick={() => {
                  setSettingsData(record);
                  return showSettingsModal();
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const trackColumns: ColumnsType<Record<string, string>> = [
    {
      title: "Sensor ID",
      dataIndex: "nodeId",
      key: "nodeId",
    },
    {
      title: "Location",
      key: "location",
      align: "center",
      render: (record: Record<string, any>) => {
        return (
          <Space>
            <PushpinOutlined onClick={() => showMap(record.lat, record.log)} />
            <Space direction="vertical" size={3}>
              <span>
                <Text strong>Latitude:</Text>
                <Text>{record.lat ? " " + record.lat : " N/A"}</Text>
              </span>
              <span>
                <Text strong>Longitude:</Text>
                <Text>{record.log ? " " + record.log : " N/A"}</Text>
              </span>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Max " + "T" + String.fromCharCode(176) + "C",
      key: "maxtemp",
      align: "center",
      sorter: (a, b) => {
        const aNum = Number(a.max_temp);
        const bNum = Number(b.max_temp);
        return aNum - bNum;
      },
      render: (record: Record<string, any>) => {
        return (
          <Space direction="vertical" size={3}>
            <Space>
              <StateMarker type={record.max_temp ? "success" : "default"} />
              <Text>{record.max_temp ? record.max_temp : "N/A"}</Text>
            </Space>
            <Text>{record.maxtime}</Text>
          </Space>
        );
      },
    },
    {
      title: "Min " + "T" + String.fromCharCode(176) + "C",
      key: "mintemp",
      align: "center",
      sorter: (a, b) => {
        const aNum = Number(a.min_temp);
        const bNum = Number(b.min_temp);
        return aNum - bNum;
      },
      render: (record: Record<string, any>) => {
        return (
          <Space direction="vertical" size={3}>
            <Space>
              <StateMarker type={record.min_temp ? "success" : "default"} />
              <Text>{record.min_temp ? record.min_temp : "N/A"}</Text>
            </Space>
            <Text>{record.mintime}</Text>
          </Space>
        );
      },
    },
    {
      title: "Batt (mV)",
      key: "batt volts",
      align: "center",
      sorter: (a, b) => {
        const aNum = Number(a.batt_mv);
        const bNum = Number(b.batt_mv);
        return aNum - bNum;
      },
      render: (record: Record<string, any>) => {
        return (
          <Space direction="vertical" size={3}>
            <Space>
              <StateMarker type={(record.batt_mv > 3300) ? "success" : "default"} />
              <Text>{record.batt_mv ? record.batt_mv : "N/A"}</Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "TimeStamp",
      key: "timestamp",
      align: "center",
      sorter: (a, b) => {
        const aNum = Number(a.arrivedTS);
        const bNum = Number(b.arrivedTS);
        return aNum - bNum;
      },
      render: (record: Record<string, any>) => {
        return (
          <Space direction="vertical" size={3}>
            <Space>
              {/* <StateMarker type={(record.arrivedts > 3300) ? "success" : "default"} /> */}
              <Text>{record.arrivedTS ? record.arrivedTS : "N/A"}</Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Details",
      key: "details",
      align: "center",
      render: (record: Record<string, string>) => {
        const index = Number(record.index);
        return (
          <Space>
            <Tooltip placement="top" title="View temperature and humidity charts">
              <Button
                loading={isDownloading[index]}
                key={record.nodeId + "-chart"}
                icon={<LineChartOutlined />}
                onClick={() => {
                  const downloadStatus = isDownloading;
                  const index = Number(record.index);
                  downloadStatus[index] = true;
                  setIsDownloading([...downloadStatus]);
                  return showModal(record);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const columns =
    queries.mode === "track" ? trackColumns : [...staticColumnsPrefix, ...locationNameColumn, ...staticColumns];
  return (
    <Card>
      <Row>
        <Col span={12}>
          <Title level={5}>Temperature sensors for {orgName}</Title>
        </Col>
        <Col span={12}>
          <Row justify="end">
            {isAdmin && queries.mode !== "track" && (
              <>
                <Button
                  key="btn-add-user"
                  type="primary"
                  className="margin-right"
                  icon={<UserAddOutlined />}
                  onClick={() => {
                    return showNewUserModal();
                  }}
                >
                  Add User
                </Button>
                <Button
                  key="btn-config-notifications"
                  type="primary"
                  icon={<NotificationOutlined />}
                  onClick={() => {
                    return showDrawer();
                  }}
                >
                  Configure notifications
                </Button>
                <br />
                <br />
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Table
        pagination={{
          hideOnSinglePage: true,
        }}
        scroll={{ x: "1000" }}
        key="sensor-table"
        dataSource={sensorInfoData}
        columns={columns}
        size="small"
        loading={isLoading}
      />
      <Modal
        key="sensor-modal"
        style={{ top: 50 }}
        width={800}
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {queries.mode !== "track" ? (
          <SensorDataCharts sensorData={modalData} type={sensorType} />
        ) : (
          <TrackTempChart sensorData={modalData} />
        )}
      </Modal>
      <Modal
        key="settings-modal"
        style={{ top: 50 }}
        width={400}
        title="Settings"
        visible={isSettingsModalVisible}
        footer={null}
        onOk={handleSettingsOk}
        onCancel={handleSettingsCancel}
      >
        <SensorSettingsForm sensorParams={settingsData} onSubmit={handleSettingsOk} />
      </Modal>
      <Modal
        style={{ top: 50 }}
        width={400}
        title="Create new user"
        visible={isNewUserModalVisible}
        footer={null}
        onOk={handleNewUserModalOk}
        onCancel={handleNewUserModalCancel}
      >
        <AddUserForm onOk={handleNewUserModalOk} />
      </Modal>
      <Modal
        style={{ top: 50 }}
        width={600}
        title="Map"
        visible={isMapVisible}
        footer={null}
        onOk={() => {
          setMapVisible(false);
          setLat(0.0);
          setLon(0.0);
        }}
        onCancel={() => {
          setMapVisible(false);
          setLat(0.0);
          setLon(0.0);
        }}
      >
        <Map lat={lat} lng={lon} />
      </Modal>
      <Drawer
        width={480}
        title="Notification Settings"
        placement="right"
        closable={true}
        onClose={onCloseDrawer}
        visible={isDrawerVisible}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Report Email IDs" key="1">
            <NotificationsTable orgParams={orgDetails} type="reportEmail" onSubmit={onCloseDrawer} />
          </TabPane>
          <TabPane tab="Alert Email IDs" key="2">
            <NotificationsTable orgParams={orgDetails} type="alertEmail" onSubmit={onCloseDrawer} />
          </TabPane>
          <TabPane tab="Alert Telegram Chat IDs" key="3">
            <NotificationsTable orgParams={orgDetails} type="alertTelegram" onSubmit={onCloseDrawer} />
          </TabPane>
        </Tabs>
      </Drawer>
    </Card>
  );
};

export default FreezerSensorTable;
