import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map: React.FC<{ lat: number; lng: number }> = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyD2i4t0z4fjbmsmOVoRLNbMOVoBxUKeCmg",
  });

  if (!(lat > -90 && lat < 90)) {
    lat = 0;
  }

  if (!(lng > -180 && lng < 180)) {
    lng = 0;
  }

  const center = {
    lat: lat,
    lng: lng,
  };

  const [map, setMap] = React.useState(null);

  const onCenterChanged = () => {
    setMap(map);
  };

  // const onLoad = (marker: unknown) => {
  //   console.log('marker: ', marker);
  // };

  return isLoaded ? (
    <GoogleMap
      id={"id" + lat + lng}
      onCenterChanged={onCenterChanged}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
    >
      <Marker position={center} />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default React.memo(Map);
